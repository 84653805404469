// @file vue composable for native bridge watchers on surface

import appCan from '@@/bits/app_can'
import { unboundedWatch } from '@@/bits/vue'
import {
  editPost as editPostMessage,
  openMapLocationPicker,
  showCommentsPanel,
  updateActiveContributorsList,
} from '@@/native_bridge/actions'
import { listen } from '@@/native_bridge/listen'
import postMessage from '@@/native_bridge/post_message'
import { createCommentMenuAlertSheet, showNativeUI } from '@@/native_bridge/ui'
import { useExpandedPostStore } from '@@/pinia/expanded_post'
import { useNativeAppStore } from '@@/pinia/native_app'
import { useCommentsStore } from '@@/pinia/surface_comments'
import { useSurfaceContributingStatusStore } from '@@/pinia/surface_contributing_status'
import { useSurfaceDraftsStore } from '@@/pinia/surface_drafts'
import { useSurfaceMapStore } from '@@/pinia/surface_map'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { useNativePostActionMenu } from '@@/vuecomposables/native_post_action_menu'
import { storeToRefs } from 'pinia'

export const useSurfaceNativeBridgeWatchers = (): void => {
  const expandedPostStore = useExpandedPostStore()
  const surfacePostsStore = useSurfacePostsStore()
  const nativeAppStore = useNativeAppStore()
  const surfaceDraftsStore = useSurfaceDraftsStore()
  const commentsStore = useCommentsStore()
  const surfaceContributingStatusStore = useSurfaceContributingStatusStore()
  const surfaceMapStore = useSurfaceMapStore()

  const { expandedPost } = storeToRefs(expandedPostStore)
  const { savedPostEntitiesByCid } = storeToRefs(surfacePostsStore)
  const { activeDraft } = storeToRefs(surfaceDraftsStore)
  const { commentUnderActionId, xCommentsPanel, commentsPanelPostId } = storeToRefs(commentsStore)
  const { activeContributorsList } = storeToRefs(surfaceContributingStatusStore)
  const { isPickingLocation } = storeToRefs(surfaceMapStore)

  // Even though listeners should only be used by the main surface container component which shouldn't unmount until user exits the app,
  // we use unboundedWatch here so that it is not tied to any specific component.
  void unboundedWatch(expandedPost, () => {
    void nativeAppStore.postSurfaceState()
  })

  void unboundedWatch(activeDraft, (currentDraft, lastDraft) => {
    if (currentDraft == null) return
    if (currentDraft?.cid !== lastDraft?.cid) {
      // when editing, app needs to know whether it's a saved post vs a draft.
      const wishId: number | undefined = savedPostEntitiesByCid.value[currentDraft.cid]?.id
      postMessage(editPostMessage(currentDraft, wishId))
    }
  })

  void unboundedWatch(commentUnderActionId, (currentCommentId) => {
    if (currentCommentId == null) return
    listen('edit_comment', (): void => {
      commentsStore.startEditingCommentV2({ commentId: currentCommentId })
    })
    listen('delete_comment', (): void => {
      commentsStore.deleteComment({ commentId: currentCommentId })
    })
    showNativeUI(createCommentMenuAlertSheet(currentCommentId))
  })

  void unboundedWatch(activeContributorsList, (currentList) => {
    postMessage(updateActiveContributorsList(currentList))
  })

  void unboundedWatch(isPickingLocation, (isCurrentlyPickingLocation) => {
    if (!appCan('useNativeMapLocationPicker')) return
    if (!isCurrentlyPickingLocation) return
    postMessage(openMapLocationPicker())
    listen('map_location_picked', async ({ location, name }): Promise<void> => {
      if (location && name) {
        const LatLng = (await import('@@/bits/map')).LatLng
        surfaceMapStore.pickLocation({ latLng: new LatLng(location), name })
      }
      surfaceMapStore.stopPickingLocation()
    })
  })

  void unboundedWatch(xCommentsPanel, (shouldShowCommentsPanel) => {
    // older versions of the app should still show the web based comments panel
    if (!appCan('useNativeCommentsPanel')) {
      return
    }

    if (shouldShowCommentsPanel) {
      postMessage(showCommentsPanel(commentsPanelPostId.value))
      commentsStore.hideCommentsPanel()
    }
  })

  useNativePostActionMenu()
}
