// @file Composable that provides methods to upload a file to a comment.
import { __ } from '@@/bits/intl'
import { FILE_TOO_LARGE_ERROR, startUpload, UploadJob } from '@@/bits/uploader'
import { useGlobalAlertDialogStore } from '@@/pinia/global_alert_dialog'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceAttachmentsStore } from '@@/pinia/surface_attachments'
import { useCommentsStore } from '@@/pinia/surface_comments'
import { CommentAttachmentState, useSurfaceCommentAttachmentsStore } from '@@/pinia/surface_comment_attachments'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import type { Cid, CommentId } from '@@/types'
import { getDisplayAttributes, LinkHelpers } from '@padlet/beethoven-client'
import { storeToRefs } from 'pinia'

const INVALID_FILE_TYPE_ERROR = 'INVALID_FILE_TYPE_ERROR'

class InvalidFileTypeError extends Error {
  error = INVALID_FILE_TYPE_ERROR
}

const validateFileType = (file: File): boolean => {
  if (file.type.startsWith('image/')) return true
  if (file.type.startsWith('video/')) return true
  if (file.type.startsWith('audio/')) return true
  return false
}

export const useCommentAttachmentUploader = (): {
  validateFileType: typeof validateFileType
  uploadFileForNewComment: typeof uploadFileForNewComment
  uploadFileForEditedComment: typeof uploadFileForEditedComment
  finishPickingAttachmentForNewComment: typeof finishPickingAttachmentForNewComment
  finishPickingAttachmentForEditedComment: typeof finishPickingAttachmentForEditedComment
  handleErrorPickingAttachmentForNewComment: typeof handleErrorPickingAttachmentForNewComment
  handleErrorPickingAttachmentForEditedComment: typeof handleErrorPickingAttachmentForEditedComment
  addAttachmentForNewComment: typeof addAttachmentForNewComment
  addAttachmentForEditedComment: typeof addAttachmentForEditedComment
  setDummyFileUploadProgressForNewComment: typeof setDummyFileUploadProgressForNewComment
  setDummyFileUploadProgressForEditedComment: typeof setDummyFileUploadProgressForEditedComment
} => {
  const { openAlertDialog } = useGlobalAlertDialogStore()

  const surfaceStore = useSurfaceStore()
  const { uploadLimit } = storeToRefs(surfaceStore)
  const { showOverFileSizeModal } = surfaceStore

  const surfacePostsStore = useSurfacePostsStore()
  const { postEntitiesByCid } = storeToRefs(surfacePostsStore)

  const {
    isUploadingAttachmentForNewComment,
    isUploadingAttachmentForEditedComment,
    startWritingNewComment,
    updateNewCommentUploadingFile,
    updateNewCommentUploadingFileProgress,
    updateNewCommentAttachment,
    updateEditedDraftCommentUploadingFile,
    updateEditedDraftCommentUploadingFileProgress,
    updateEditedDraftCommentAttachment,
    removeUploadingFileForNewComment,
    removeAttachmentForNewComment,
    removeUploadingFileForEditedComment,
    removeAttachmentForEditedComment,
  } = useCommentsStore()
  const { newCommentAttachmentStateForPostCid, updateNewCommentAttachmentState, updateEditedCommentAttachmentState } =
    useSurfaceCommentAttachmentsStore()
  const { fetchAndStoreLinkAttributes } = useSurfaceAttachmentsStore()

  // #region NEW COMMENTS
  const finishPickingAttachmentForNewComment = async ({
    postCid,
    url,
  }: {
    postCid: Cid
    url: string
  }): Promise<void> => {
    updateNewCommentAttachment({ postCid, attachment: url })
    // If a link is not an image or a video (mostly comes from Google Drive, sometimes it refuses to be uploaded and the raw
    // Google Drive link is returned), we find the preview image URL and use it as the attachment.
    const link = await fetchAndStoreLinkAttributes(url)
    if (link != null && LinkHelpers.isGoogleDriveFile(link)) {
      // What we pass to `width` here doesn't matter because we only need the original image URL.
      const displayAttrs = getDisplayAttributes(link, { width: 0 })
      const imageUrl = displayAttrs?.original_image_url ?? null
      // If at this point, an image URL is still not found, we reject the attachment.
      if (imageUrl == null) {
        throw new Error(`No image URL found for attachment ${url}`)
      }
      updateNewCommentAttachment({ postCid, attachment: imageUrl })
    } else if (
      link == null ||
      (!LinkHelpers.isPhoto(link) && !LinkHelpers.isVideo(link) && !LinkHelpers.isAudio(link))
    ) {
      throw new InvalidFileTypeError(`Invalid file type ${link?.content_type ?? 'unknown'}`)
    }
    const currentState = newCommentAttachmentStateForPostCid(postCid)
    if (currentState === CommentAttachmentState.LOADING) {
      // We only advance the state to PREVIEW if the comment is still in the LOADING state.
      // If it's not, chances are the user has already cancelled the attachment and the state
      // is now IDLE/undefined.
      updateNewCommentAttachmentState({ postCid, state: CommentAttachmentState.PREVIEW })
    }
  }

  const handleErrorPickingAttachmentForNewComment = ({
    err,
    postCid,
    file,
  }: {
    err: Error & { error?: string }
    postCid: Cid
    file?: File
  }): void => {
    removeAttachmentForNewComment({ postCid })
    updateNewCommentAttachmentState({ postCid, state: CommentAttachmentState.IDLE })
    if (err.error === FILE_TOO_LARGE_ERROR && file != null) {
      showOverFileSizeModal({ file })
    } else if (err.error === INVALID_FILE_TYPE_ERROR) {
      openAlertDialog({
        title: __('Invalid file type'),
        body: __('Sorry, the file you selected is not supported.'),
      })
    } else {
      openAlertDialog({
        title: __('There was an error loading the attachment'),
        body: __('Sorry, there was an error uploading your file.'),
      })
    }
  }

  const uploadFileForNewComment = ({ postCid, file }: { postCid: Cid; file: File }): void => {
    if (!validateFileType(file)) {
      handleErrorPickingAttachmentForNewComment({
        err: new InvalidFileTypeError(`Invalid file type ${file.type}`),
        postCid,
        file,
      })
      return
    }

    // Mark the comment as being written to properly set the `activeNewCommentPostId` state
    // and ensure the comment edit drawer can show up.
    const postId = postEntitiesByCid.value[postCid]?.id
    if (postId != null) startWritingNewComment({ postId })
    updateNewCommentAttachmentState({ postCid, state: CommentAttachmentState.LOADING })

    const uploadJob = startUpload(file, { maxFileSize: uploadLimit.value })
    updateNewCommentUploadingFile({ postCid, file, uploadJob })
    updateNewCommentUploadingFileProgress({ postCid, progress: 0 })

    uploadJob.on('progress', (progress) => {
      updateNewCommentUploadingFileProgress({ postCid, progress })
    })
    uploadJob.on('done', ({ url }) => {
      void finishPickingAttachmentForNewComment({ postCid, url })
        .then(() => {
          removeUploadingFileForNewComment({ postCid })
        })
        .catch((err) => {
          handleErrorPickingAttachmentForNewComment({ err, postCid, file })
        })
    })
    uploadJob.on('error', (err) => {
      handleErrorPickingAttachmentForNewComment({ err, postCid, file })
    })
  }

  // Use this if the file is being uploaded elsewhere but we still want to display an upload progress UI
  // For eg, mobile app makes the upload and sends native bridge messages to update the progress.
  const setDummyFileUploadProgressForNewComment = (postCid: Cid, progress: number): void => {
    // If there's no active new comment, set one for the given post.
    // Then, pass it a dummy File and UploadJob.
    // The upload process is taking place elsewhere, so they won't be used
    if (!isUploadingAttachmentForNewComment({ postCid })) {
      const postId = postEntitiesByCid.value[postCid]?.id
      if (postId != null) startWritingNewComment({ postId })
      updateNewCommentAttachmentState({ postCid, state: CommentAttachmentState.LOADING })
      updateNewCommentUploadingFile({
        postCid,
        file: new File([], 'empty.txt', { type: 'text/plain' }),
        uploadJob: new UploadJob(),
      })
    }
    updateNewCommentUploadingFileProgress({ postCid, progress })
  }

  const addAttachmentForNewComment = async ({ postCid, url }: { postCid: Cid; url: string }): Promise<void> => {
    const postId = postEntitiesByCid.value[postCid]?.id
    if (postId != null) startWritingNewComment({ postId })
    updateNewCommentAttachmentState({ postCid, state: CommentAttachmentState.LOADING })
    try {
      await finishPickingAttachmentForNewComment({ postCid, url })
    } catch (err) {
      handleErrorPickingAttachmentForNewComment({ err, postCid })
    }
  }
  // #endregion

  // #region EDITED COMMENTS
  const finishPickingAttachmentForEditedComment = async ({
    commentId,
    url,
  }: {
    commentId: CommentId
    url: string
  }): Promise<void> => {
    updateEditedDraftCommentAttachment({ commentId, attachment: url })
    // As of 19 April 2024, we only support images in comments.
    // Therefore, if a link is not an image (mostly comes from Google Drive, sometimes it refuses to be uploaded and the raw
    // Google Drive link is returned), we find the preview image URL and use it as the attachment.
    const link = await fetchAndStoreLinkAttributes(url)
    if (link != null && LinkHelpers.isGoogleDriveFile(link)) {
      // What we pass to `width` here doesn't matter because we only need the original image URL.
      const displayAttrs = getDisplayAttributes(link, { width: 0 })
      const imageUrl = displayAttrs?.original_image_url ?? null
      // If at this point, an image URL is still not found, we reject the attachment.
      if (imageUrl == null) {
        throw new Error(`No image URL found for attachment ${url}`)
      }
      updateEditedDraftCommentAttachment({ commentId, attachment: imageUrl })
    } else if (
      link == null ||
      (!LinkHelpers.isPhoto(link) && !LinkHelpers.isVideo(link) && !LinkHelpers.isAudio(link))
    ) {
      throw new InvalidFileTypeError(`Invalid file type ${link?.content_type ?? 'unknown'}`)
    }
    updateEditedCommentAttachmentState({ commentId, state: CommentAttachmentState.PREVIEW })
  }

  const handleErrorPickingAttachmentForEditedComment = ({
    err,
    commentId,
    file,
  }: {
    err: Error & { error?: string }
    commentId: CommentId
    file?: File
  }): void => {
    removeAttachmentForEditedComment({ commentId })
    updateEditedCommentAttachmentState({ commentId, state: CommentAttachmentState.IDLE })
    if (err.error === FILE_TOO_LARGE_ERROR && file != null) {
      showOverFileSizeModal({ file })
    } else if (err.error === INVALID_FILE_TYPE_ERROR) {
      openAlertDialog({
        title: __('Invalid file type'),
        body: __('Sorry, the file you selected is not supported.'),
      })
    } else {
      openAlertDialog({
        title: __('There was an error loading the attachment'),
        body: __('Sorry, there was an error uploading your file.'),
      })
    }
  }

  const uploadFileForEditedComment = ({ commentId, file }: { commentId: CommentId; file: File }): void => {
    if (!validateFileType(file)) {
      handleErrorPickingAttachmentForEditedComment({
        err: new InvalidFileTypeError(`Invalid file type ${file.type}`),
        commentId,
        file,
      })
      return
    }

    updateEditedCommentAttachmentState({ commentId, state: CommentAttachmentState.LOADING })

    const uploadJob = startUpload(file, { maxFileSize: uploadLimit.value })
    updateEditedDraftCommentUploadingFile({ commentId, file, uploadJob })
    updateEditedDraftCommentUploadingFileProgress({ commentId, progress: 0 })

    uploadJob.on('progress', (progress) => {
      updateEditedDraftCommentUploadingFileProgress({ commentId, progress })
    })
    uploadJob.on('done', ({ url }) => {
      void finishPickingAttachmentForEditedComment({ commentId, url })
        .then(() => {
          removeUploadingFileForEditedComment({ commentId })
        })
        .catch((err) => {
          handleErrorPickingAttachmentForEditedComment({ err, commentId, file })
        })
    })
    uploadJob.on('error', (err) => {
      handleErrorPickingAttachmentForEditedComment({ err, commentId, file })
    })
  }

  const addAttachmentForEditedComment = async ({
    commentId,
    url,
  }: {
    commentId: CommentId
    url: string
  }): Promise<void> => {
    updateEditedCommentAttachmentState({ commentId, state: CommentAttachmentState.LOADING })
    try {
      await finishPickingAttachmentForEditedComment({ commentId, url })
    } catch (err) {
      handleErrorPickingAttachmentForEditedComment({ err, commentId })
    }
  }

  // Use this if the file is being uploaded elsewhere but we still want to display an upload progress UI
  // For eg, mobile app makes the upload and sends native bridge messages to update the progress.
  const setDummyFileUploadProgressForEditedComment = (commentId: CommentId, progress: number): void => {
    if (!isUploadingAttachmentForEditedComment({ commentId })) {
      updateEditedCommentAttachmentState({ commentId, state: CommentAttachmentState.LOADING })
      updateEditedDraftCommentUploadingFile({
        commentId,
        file: new File([], 'empty.txt', { type: 'text/plain' }),
        uploadJob: new UploadJob(),
      })
    }
    updateEditedDraftCommentUploadingFileProgress({ commentId, progress })
  }
  // #endregion

  return {
    addAttachmentForNewComment,
    addAttachmentForEditedComment,
    validateFileType,
    uploadFileForNewComment,
    uploadFileForEditedComment,
    finishPickingAttachmentForNewComment,
    finishPickingAttachmentForEditedComment,
    handleErrorPickingAttachmentForNewComment,
    handleErrorPickingAttachmentForEditedComment,
    setDummyFileUploadProgressForNewComment,
    setDummyFileUploadProgressForEditedComment,
  }
}
