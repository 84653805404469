// @file composable for native bridge listeners
// TODO: [to-be-migrated][post_action]
// TODO: [to-be-migrated][surface]

import device from '@@/bits/device'
import { DOMContentLoadedPromise } from '@@/bits/dom'
import { captureMessage } from '@@/bits/error_tracker'
import window from '@@/bits/global'
import { buildUrlFromPath, currentPath, navigateTo } from '@@/bits/location'
import { setSafeAreaInsets } from '@@/bits/safe_area_insets'
import type { EdgeInsets } from '@@/bits/window'
import {
  firstTouch as nativeBridgeFirstTouchMessage,
  openLink as nativeBridgeOpenLinkMessage,
  pageReady as nativeBridgePageReadyMessage,
  postFilterState as postFilterStateMessage,
} from '@@/native_bridge/actions'
import { listen } from '@@/native_bridge/listen'
import postMessage from '@@/native_bridge/post_message'
import type { LinkType, ListenedMessage } from '@@/native_bridge/types'
import { useExpandedPostStore } from '@@/pinia/expanded_post'
import type { LinkTarget, NativeAppOpenLink } from '@@/pinia/native_app'
import { useNativeAppStore } from '@@/pinia/native_app'
import { useSurfaceStore } from '@@/pinia/surface'
import { useCommentsStore } from '@@/pinia/surface_comments'
import { useSurfaceDraftsStore } from '@@/pinia/surface_drafts'
import { useSurfaceFilterStore } from '@@/pinia/surface_filter'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { useSurfacePostActionStore } from '@@/pinia/surface_post_action'
import { useSurfacePostSearchStore } from '@@/pinia/surface_post_search'
import { useSurfaceUserContributorsStore } from '@@/pinia/surface_user_contributors'
import { useWindowSizeStore } from '@@/pinia/window_size'
import type { Cid, DraftPost, Post } from '@@/types'
import type { FilterGroup } from '@@/types/surface'
import { useSurfaceNativeBridgeWatchers } from '@@/vuecomposables/native_bridge_surface_watchers'
import { useCommentAttachmentUploader } from '@@/vuecomposables/useCommentAttachmentUploader'
import { useHandleFirstVerticalSwipe } from '@@/vuecomposables/useHandleFirstVerticalSwipe'
import { storeToRefs } from 'pinia'
import { watch } from 'vue'

const BRIDGED_LINK_TYPE_ATTRIBUTE = 'data-bridged-link'

interface Props {
  for: 'surface' | 'whiteboard' | 'slideshow'
}

export interface CommentAttachmentPayload {
  url: string
  postId: number
  commentId?: number
}

export const useNativeBridgeListeners = (props: Props): void => {
  const nativeAppStore = useNativeAppStore()
  const surfaceStore = useSurfaceStore()
  const surfacePostsStore = useSurfacePostsStore()
  const surfacePostSearchStore = useSurfacePostSearchStore()
  const surfacePostActionStore = useSurfacePostActionStore()
  const surfaceFilterStore = useSurfaceFilterStore()
  const surfaceDraftsStore = useSurfaceDraftsStore()
  const surfaceUserContributorsStore = useSurfaceUserContributorsStore()
  const windowSizeStore = useWindowSizeStore()
  const surfaceCommentsStore = useCommentsStore()
  const commentAttachmentUploader = useCommentAttachmentUploader()

  const { isSlideshow } = storeToRefs(surfaceStore)
  const { linkToOpen } = storeToRefs(nativeAppStore)
  const { postEntitiesById } = storeToRefs(surfacePostsStore)
  const { postSurfaceState, hideAllPanelsAndMenus, openLink, clearOpenLink } = nativeAppStore
  const { refresh } = surfaceStore

  /* ----------------------------- */
  /* GLOBAL LISTENERS              */
  /* ----------------------------- */
  /**
   * Posts a message when the DOM is ready.
   * If the device is not iOS or Android, or if the current path includes 'beethoven/attach' or 'mobile-app/attach',
   * or if the code is running inside an iframe, the function will return early.
   * If the code is running inside a slideshow, it will also return early since we don't want to redirect users to a wall not found page, since it is not a wall.
   * Otherwise, it will post a message using the native bridge and navigate to a specific URL.
   */
  const postMessageOnDomReady = (): void => {
    if (!(device.ios || device.android)) return
    if (currentPath().includes('beethoven/attach') || currentPath().includes('mobile-app/attach')) return

    const isDisplayedInIFrame = window.self !== window.top
    if (isDisplayedInIFrame) return

    if (isSlideshow.value) return

    postMessage(nativeBridgePageReadyMessage(), () => navigateTo(buildUrlFromPath('/ios-app/page-ready')))
    void postSurfaceState()
  }

  const installGlobalListeners = (): void => {
    document.body.addEventListener('click', onDataBridgedLinkClick)
    listen('refresh_padlet', () => {
      // manually refresh the padlet when this messaged is received from native app
      refresh({ isAutoRefresh: false })
    })
    listen('cancel_show', hideAllPanelsAndMenus)
    listen('post_surface_state', postSurfaceState)
    void DOMContentLoadedPromise.then(postMessageOnDomReady)
  }

  /* ----------------------------- */
  /* SURFACE SPECIFIC LISTENERS    */
  /* ----------------------------- */
  const installSearchAndFilterPostsListeners = (): void => {
    listen('update_search_term', (message: { message_type: 'update_search_term'; payload: { searchTerm: string } }) => {
      surfacePostSearchStore.setSearchTerm(message.payload.searchTerm)
    })
    listen('show_post_filters', () => {
      const filters = surfaceFilterStore.orderedFilterState
      postMessage(postFilterStateMessage(filters))
    })
    listen(
      'update_post_filter',
      (message: {
        message_type: 'update_post_filter'
        payload: { group: FilterGroup; key: string; value: boolean }
      }) => {
        const { group, key, value } = message.payload
        surfaceFilterStore.updatePostFilter(group, key, value)
      },
    )
    listen(
      'reset_post_filters',
      (message: { message_type: 'reset_post_filters'; payload: { group?: FilterGroup } }) => {
        surfaceFilterStore.resetPostFilters(message.payload.group)
      },
    )
  }

  const installPostMenuListeners = (): void => {
    listen('pin_post', (message: ListenedMessage & { postCid: Cid }) => {
      surfacePostsStore.pinPost(message.postCid)
      surfacePostActionStore.hidePostActionMenu()
    })
    listen('unpin_post', (message: ListenedMessage & { postCid: Cid }) => {
      surfacePostsStore.unpinPost(message.postCid)
      surfacePostActionStore.hidePostActionMenu()
    })
  }

  const installDraftUpdateListeners = (): void => {
    listen('update_draft', (message: ListenedMessage & { payload: DraftPost }) => {
      surfaceDraftsStore.updateDraft(message.payload)
    })

    listen('remove_draft', (message: ListenedMessage & { payload: { cid: Cid } }) => {
      void surfaceDraftsStore.removeDraft(message.payload.cid)
    })

    listen('publish_all_drafts', (_message: ListenedMessage) => {
      void surfaceDraftsStore.publishAllPostDrafts()
    })

    listen('remove_all_drafts', (_message: ListenedMessage) => {
      void surfaceDraftsStore.removeAllPostDrafts()
    })

    listen('start_editing_draft', (message: ListenedMessage & { payload: { cid: Cid } }) => {
      surfaceDraftsStore.startEditingDraft(message.payload.cid)
    })

    listen('stop_editing_draft', () => {
      surfaceDraftsStore.stopEditingDraft()
    })

    listen('cancel_editing_draft', () => {
      void surfaceDraftsStore.cancelEditingDraft()
    })

    listen('publish_draft', (message: ListenedMessage & { payload: { cid: Cid } }) => {
      void surfaceDraftsStore.publishDraft({ cid: message.payload.cid })
    })

    // TODO: Deprecate this listener
    listen('save_post', (message: ListenedMessage & { payload: { attributes: Post } }) => {
      void surfacePostsStore.savePost({ attributes: message.payload.attributes })
    })
  }

  const installExpandedPostListeners = (): void => {
    listen('hide_expanded_post', (): void => {
      useExpandedPostStore().unexpandPost()
      void postSurfaceState()
    })
    // if the post has just been created by the mobile app's native post editor, postCid inside the webview will be c_new<number> instead of c<post_id>
    // => to be safe, find post by id first in set_expanded_post_id and show_post_action_menu
    listen('set_expanded_post_id', (message: ListenedMessage & { payload: { post_id: string } }): void => {
      const postCid = postEntitiesById.value[message.payload.post_id]?.cid
      if (postCid == null) {
        return
      }
      useExpandedPostStore().expandPost({ postCid })
      void postSurfaceState()
    })
    listen('show_post_action_menu', (message: ListenedMessage & { payload: { post_id: string } }): void => {
      const postCid = postEntitiesById.value[message.payload.post_id]?.cid
      if (postCid == null) {
        return
      }
      surfacePostActionStore.showPostActionMenu({ postCid })
    })
    listen('hide_post_action_menu', (): void => {
      surfacePostActionStore.hidePostActionMenu()
    })
  }

  const installCommentContentPickerListeners = (): void => {
    listen(
      'add_comment_attachment',
      async (
        message: ListenedMessage & {
          payload: CommentAttachmentPayload
        },
      ) => {
        const { postId, commentId, url } = message.payload

        if (url == null) return

        if (commentId == null) {
          // adding comment: starts a new comment, sets its attachment, clears any existing upload state if any
          const postCid = surfacePostsStore.postEntitiesById[postId].cid
          if (postCid == null) return

          try {
            await commentAttachmentUploader.addAttachmentForNewComment({ postCid, url })
            surfaceCommentsStore.removeUploadingFileForNewComment({ postCid })
          } catch (err) {
            captureMessage('Could not add attachment for new comment', { context: { error: err } })
          }
        } else if (surfaceCommentsStore.isCommentBeingEdited(commentId)) {
          // editing comment: set its attachment, and clear any existing upload state if any
          try {
            await commentAttachmentUploader.addAttachmentForEditedComment({ commentId, url })
            surfaceCommentsStore.removeUploadingFileForEditedComment({ commentId })
          } catch (err) {
            captureMessage('Could not add attachment for comment being edited', { context: { error: err } })
          }
        }
      },
    )

    listen(
      'comment_attachment_upload_progress',
      async (
        message: ListenedMessage & {
          payload: {
            postId: number
            commentId?: number
            progress: number | undefined
          }
        },
      ) => {
        const { postId, commentId, progress } = message.payload
        if (progress == null) return
        if (commentId == null) {
          // adding comment: this will start a new comment if necessary and populate dummy upload state.
          // then, all subsequent message will purely update upload progress
          const postCid = surfacePostsStore.postEntitiesById[postId].cid
          if (postCid == null) return
          commentAttachmentUploader.setDummyFileUploadProgressForNewComment(postCid, progress)
        } else if (surfaceCommentsStore.isCommentBeingEdited(commentId)) {
          // editing comment: user is editing an existing comment. just send progress updates.
          commentAttachmentUploader.setDummyFileUploadProgressForEditedComment(commentId, progress)
        }
      },
    )
  }

  const installWhiteboardContentPickerListeners = (): void => {
    listen(
      'add_whiteboard_attachment',
      (
        message: ListenedMessage & {
          payload: { url: string }
        },
      ) => {
        const { url } = message.payload
        surfacePostsStore.createPostImmediately({
          attributes: {
            attachment: url,
            wish_content: {
              attachment_props: {
                url,
              },
              is_processed: false,
            },
          },
        })
      },
    )
  }

  /* ----------------------------- */
  /* NATIVE APP SPECIFIC LISTENERS */
  /* ----------------------------- */
  /**
   * Handles the click event on anchor tags with the attribute 'data-bridged-link'.
   * @param e - The click event.
   */
  const onDataBridgedLinkClick = (e: Event): void => {
    const clickTarget = e.target as HTMLElement
    // only process anchor tags
    const target = clickTarget.closest('a')
    if (target == null) return
    // only process anchor tags with our special class
    if (target.hasAttribute(BRIDGED_LINK_TYPE_ATTRIBUTE)) {
      const anchorHref = target.getAttribute('href')
      const anchorTarget = target.getAttribute('target') as LinkTarget | null
      const linkType = target.getAttribute(BRIDGED_LINK_TYPE_ATTRIBUTE) as LinkType | null
      e.preventDefault()
      const openLinkPayload: NativeAppOpenLink = {
        linkType,
        target: anchorTarget,
        url: anchorHref,
      }

      switch (linkType) {
        case 'padlet':
          openLinkPayload.contentCategory = 'page'
          openLinkPayload.contentSubcategory = 'padlet'
          break
        case 'slideshow':
          openLinkPayload.contentCategory = 'page'
          openLinkPayload.contentSubcategory = 'slideshow'
          break
        case 'profile':
          openLinkPayload.contentCategory = 'page'
          openLinkPayload.contentSubcategory = 'profile'
          openLinkPayload.username = target.getAttribute('data-bridged-link-username')
          break
      }
      openLink(openLinkPayload)
    }
  }

  const installSafeAreaListener = (): void => {
    listen('set_safe_area_insets', (message: { message_type: string; safeAreaInsets: EdgeInsets }) => {
      setSafeAreaInsets(message.safeAreaInsets)
      windowSizeStore.safeAreaInsets = message.safeAreaInsets
    })
  }

  /* ----------------------------- */
  /* MOBILE-SPECIFIC LISTENERS     */
  /* ----------------------------- */

  // Collapse header on first vertical swipe
  const installFirstTouchListenerForNativeHeader = (): void => {
    useHandleFirstVerticalSwipe(() => postMessage(nativeBridgeFirstTouchMessage({ isVerticalTouch: true })))
  }

  /* ---------------------- */
  /* GLOBAL WATCHERS        */
  /* ---------------------- */
  watch(linkToOpen, (newValue, oldValue) => {
    if (newValue != null && oldValue == null) {
      const { linkType, url, contentCategory, contentSubcategory, username } = newValue
      postMessage(nativeBridgeOpenLinkMessage({ url, linkType, contentCategory, contentSubcategory, username }))
      clearOpenLink()
    }
  })
  /* ---------------------- */
  /* ACTIONS                */
  /* ---------------------- */
  const setupSurfaceListeners = (): void => {
    installGlobalListeners()
    installFirstTouchListenerForNativeHeader()
    installSearchAndFilterPostsListeners()
    installPostMenuListeners()
    installDraftUpdateListeners()
    installExpandedPostListeners()
    installCommentContentPickerListeners()
    useSurfaceNativeBridgeWatchers()
  }

  const setupWhiteboardListeners = (): void => {
    installWhiteboardContentPickerListeners()
  }

  const setupSlideshowListeners = (): void => {
    installGlobalListeners()
    installSafeAreaListener()
  }

  const setupListeners = (): void => {
    if (props.for === 'surface') {
      setupSurfaceListeners()
      return
    }

    if (props.for === 'whiteboard') {
      setupWhiteboardListeners()
      return
    }

    if (props.for === 'slideshow') {
      setupSlideshowListeners()
    }
  }

  setupListeners()
  void surfaceUserContributorsStore.fetchPostAuthors()
}
