/**
 * @file Helpers to build the manifest for mobile app post action menu
 */
/* eslint-disable camelcase */
import { StaticAttachmentType } from '@@/bits/attachments'
import device from '@@/bits/device'
import { __ } from '@@/bits/intl'
import { buildSlideshowPostLink } from '@@/bits/slideshow'
import { buildCsvExportsUrl } from '@@/bits/surface_polls'
import postMessage from '@@/native_bridge/post_message'
import type { ActionMenuItemsGroup, ActionMenuWallData, DomElement, MenuItem } from '@@/native_bridge/types'
import {
  createAskToDeletePostAlert,
  createCopyPostSidePanel,
  createDomElement,
  createNativeColorMenuItem,
  createNativeMenuItem,
  createTransferPostSidePanel,
} from '@@/native_bridge/ui'
import type { Post } from '@@/types'
import { PostActionType } from '@@/vuexstore/helpers/post'
import type { BeethovenDisplayAttributes } from '@padlet/beethoven-client'

const shareStaticAttachmentMessage = (attachmentType: StaticAttachmentType): string => {
  switch (attachmentType) {
    case StaticAttachmentType.Image:
      return __('Share image')
    case StaticAttachmentType.Pdf:
      return __('Share PDF')
    default:
      return __('Share attachment')
  }
}

const showNativePostActionMenu = ({
  post,
  wall,
  linkDisplayAttributes,
  desiredActionGroups,
  isPostUnderActionExpanded,
  isPostAttachmentDownloadable,
  areAttachmentsAccessible,
  postStaticAttachmentType,
  triggerRect,
}: {
  post: Post
  wall: ActionMenuWallData
  linkDisplayAttributes: BeethovenDisplayAttributes | null
  desiredActionGroups: PostActionType[][]
  isPostUnderActionExpanded: boolean
  isPostAttachmentDownloadable: boolean
  areAttachmentsAccessible: boolean
  postStaticAttachmentType: StaticAttachmentType
  triggerRect: DOMRect | null
}): void => {
  const wallPublicKey = wall.publicKey
  const postCid = post.cid
  const postId = post.id as number
  const postUrl = post.permalink
  // for non-upload links pointing to image files, we want to use the url for the image copy stored in padlet-artifacts bucket
  const linkImagePreviewUrl =
    linkDisplayAttributes?.content_category === 'photo' ? linkDisplayAttributes?.original_image_url : null
  const attachmentUrl = post.wish_content?.attachment_props?.hotlink || linkImagePreviewUrl || post.attachment
  const slideshowLink = buildSlideshowPostLink(wall.links.show, post.id)
  const pollExportCsvLink = buildCsvExportsUrl(wallPublicKey, post)

  const menuItemsFactory: { [key in PostActionType]?: () => MenuItem } = {
    [PostActionType.ExpandPost]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Open post'),
        icon: 'post_expand',
        key: 'expand_post',
        style: 'default',
        action: {
          message_type: 'expand_post',
          post_id: postId,
        },
      })
    },
    [PostActionType.CopyPostLink]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Copy link to post'),
        icon: 'post_copy_link',
        key: 'copy_post_link_to_clipboard',
        style: 'default',
        actionHandledNatively: true,
        action: {
          message_type: 'copy_post_link_to_clipboard',
          url: postUrl,
        },
      })
    },
    [PostActionType.StartSlideshowFromThisPost]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Start slideshow from this post'),
        icon: 'play_outline',
        key: 'start_slideshow',
        style: 'default',
        actionHandledNatively: true,
        action: {
          message_type: 'start_slideshow',
          url: slideshowLink,
        },
      })
    },
    [PostActionType.CopyAttachmentLink]: (): MenuItem => {
      return createNativeMenuItem({
        name: isPostAttachmentDownloadable ? __('Copy direct link to attachment') : __('Copy attachment link'),
        icon: 'attachment_copy_link',
        key: 'copy_attachment_link_to_clipboard',
        style: 'default',
        disabled: !areAttachmentsAccessible,
        actionHandledNatively: true,
        action: {
          message_type: 'copy_attachment_link_to_clipboard',
          url: attachmentUrl,
        },
      })
    },
    [PostActionType.EditPost]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Edit post'),
        icon: 'pencil_outline',
        key: 'edit_post',
        style: 'default',
        action: {
          message_type: 'edit_post',
          post_id: postId,
        },
      })
    },
    [PostActionType.ConnectToPost]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Connect to a post'),
        icon: 'connect_outline',
        key: 'connect_post',
        style: 'default',
        action: {
          message_type: 'connect_post',
          post_id: postId,
        },
      })
    },
    [PostActionType.DisconnectFromPost]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Disconnect from a post'),
        icon: 'disconnect_outline',
        key: 'disconnect_post',
        style: 'default',
        action: {
          message_type: 'disconnect_post',
          post_id: postId,
        },
      })
    },
    [PostActionType.BringToFront]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Bring to front'),
        icon: 'send_to_front_outline',
        key: 'bring_post_to_front',
        style: 'default',
        action: {
          message_type: 'bring_post_to_front',
          post_id: postId,
        },
      })
    },
    [PostActionType.SendToBack]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Send to back'),
        icon: 'send_to_back_outline',
        key: 'send_post_to_back',
        style: 'default',
        action: {
          message_type: 'send_post_to_back',
          post_id: postId,
        },
      })
    },
    [PostActionType.TransferPost]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Transfer post'),
        icon: 'post_transfer',
        key: 'transfer_post',
        style: 'default',
        actionHandledNatively: true,
        disabled: !areAttachmentsAccessible,
        launches: {
          ...createTransferPostSidePanel(wall.mobileAppTransferPostUrlTemplate, wall.publicKey, post),
          actionMessageType: 'transfer_post',
        },
      })
    },
    [PostActionType.DuplicatePost]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Duplicate post'),
        icon: 'post_duplicate',
        key: 'copy_post',
        style: 'default',
        actionHandledNatively: true,
        disabled: !areAttachmentsAccessible,
        launches: {
          ...createCopyPostSidePanel(wall.mobileAppCopyPostUrlTemplate, wall.publicKey, post),
          actionMessageType: 'copy_post',
        },
      })
    },
    [PostActionType.SetAsPadletCover]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Set as padlet cover'),
        icon: 'padlet_cover',
        key: 'set_as_cover',
        style: 'default',
        action: {
          message_type: 'set_as_cover',
          post_id: postId,
        },
      })
    },
    [PostActionType.UnsetAsPadletCover]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Unset as padlet cover'),
        icon: 'padlet_cover',
        key: 'unset_as_cover',
        style: 'default',
        action: {
          message_type: 'unset_as_cover',
          post_id: postId,
        },
      })
    },
    [PostActionType.DeletePost]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Delete post'),
        icon: 'delete',
        key: 'ask_to_delete',
        style: 'destructive',
        actionHandledNatively: true,
        launches: {
          ...createAskToDeletePostAlert(postId),
          actionMessageType: 'ask_to_delete',
        },
      })
    },
    [PostActionType.ChangePostColor]: (): MenuItem => {
      return {
        ...createNativeColorMenuItem(wall.themeColors, wall.colorScheme),
        key: 'choose_post_color',
        style: 'default',
      }
    },
    [PostActionType.SharePostLink]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Share link to post'),
        icon: 'post_copy_link',
        key: 'share_post_link',
        style: 'default',
        actionHandledNatively: true,
        action: {
          message_type: 'share_post_link',
          url: postUrl,
        },
      })
    },
    [PostActionType.ShareAttachmentLink]: (): MenuItem => {
      return createNativeMenuItem({
        name: isPostAttachmentDownloadable ? __('Share direct link to attachment') : __('Share attachment link'),
        icon: 'attachment_copy_link',
        key: 'share_attachment_link',
        style: 'default',
        actionHandledNatively: true,
        disabled: !areAttachmentsAccessible,
        action: {
          message_type: 'share_attachment_link',
          url: attachmentUrl,
        },
      })
    },
    [PostActionType.ShareStaticAttachment]: (): MenuItem => {
      return createNativeMenuItem({
        name: shareStaticAttachmentMessage(postStaticAttachmentType),
        icon: 'share_outline',
        key: 'share_static_attachment',
        style: 'default',
        actionHandledNatively: true,
        action: {
          message_type: 'share_static_attachment',
          url: attachmentUrl,
        },
      })
    },
    [PostActionType.PinPost]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Pin post'),
        icon: 'pin',
        key: 'pin_post',
        style: 'default',
        action: {
          message_type: 'pin_post',
          postCid,
        },
      })
    },
    [PostActionType.UnpinPost]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Unpin post'),
        icon: 'pin_slash',
        key: 'unpin_post',
        style: 'default',
        action: {
          message_type: 'unpin_post',
          postCid,
        },
      })
    },
    [PostActionType.ReportPost]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Report post'),
        icon: 'flag-outline',
        key: 'report_post',
        style: 'default',
        actionHandledNatively: true,
        action: {
          message_type: 'report_post',
          wall_public_key: wallPublicKey,
          wish_id: postId,
        },
      })
    },
    [PostActionType.OpenPoll]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Open poll'),
        icon: 'poll_open',
        key: 'open_poll',
        style: 'default',
        action: {
          message_type: 'open_poll',
        },
      })
    },
    [PostActionType.OpenLocationInMapApp]: (): MenuItem => {
      return createNativeMenuItem({
        name: device.ios ? __('Open location in Apple Maps') : __('Open location in Google Maps'),
        icon: 'map-outline',
        key: 'open_location_in_map_app',
        style: 'default',
        actionHandledNatively: true,
        action: {
          message_type: 'open_location_in_map_app',
          location_point: post.location_point,
          location_name: post.location_name,
        },
      })
    },
    [PostActionType.ClosePoll]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Close poll'),
        icon: 'poll_close',
        key: 'close_poll',
        style: 'default',
        action: {
          message_type: 'close_poll',
        },
      })
    },
    [PostActionType.ExportPollResultsCSV]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Export poll results as CSV'),
        icon: 'download-outline',
        key: 'share_static_attachment',
        style: 'default',
        actionHandledNatively: true,
        action: {
          message_type: 'share_static_attachment',
          url: pollExportCsvLink,
        },
      })
    },
  }

  const menuItems: ActionMenuItemsGroup[] = []

  desiredActionGroups.forEach((actionGroup, index): void => {
    const menuItemsGroup: ActionMenuItemsGroup = { key: `group${index}`, menuItems: [] }
    actionGroup.forEach((postAction) => {
      const itemMaker = menuItemsFactory[postAction]
      if (itemMaker == null) return
      const menuItem = itemMaker()
      if (menuItem) {
        menuItemsGroup.menuItems.push(menuItem)
      }
    })

    menuItems.push(menuItemsGroup)
  })

  const element = ((): DomElement => {
    if (triggerRect == null) {
      return createDomElement(document.querySelector(`.surface-post[data-post-cid=${post.cid}]`) as Element)
    }
    const { x, y, width, height } = triggerRect
    return { type: 'rect', x, y, width, height }
  })()

  postMessage({
    message_type: 'show_action_menu',
    payload: {
      manifest: {
        type: isPostUnderActionExpanded ? 'expanded_post_action_menu' : 'post_action_menu',
        title: __('Post menu'),
        element,
        items: menuItems,
        cancel_action: {
          message_type: 'cancel_show',
        },
      },
    },
  })
}

export { showNativePostActionMenu }
