// @file utils for safe area insets
import type { EdgeInsets } from '@@/bits/window'

export const setSafeAreaInsets = (safeAreaInsets: EdgeInsets): void => {
  document.documentElement.style.setProperty('--safe-area-inset-top', `${safeAreaInsets.top}px`)
  document.documentElement.style.setProperty('--safe-area-inset-left', `${safeAreaInsets.left}px`)
  document.documentElement.style.setProperty('--safe-area-inset-right', `${safeAreaInsets.right}px`)
  document.documentElement.style.setProperty('--safe-area-inset-bottom', `${safeAreaInsets.bottom}px`)
}

export const getSafeAreaInsets = (element: Element): Record<keyof EdgeInsets, string> => {
  const top = getComputedStyle(element).getPropertyValue('--safe-area-inset-top')
  const left = getComputedStyle(element).getPropertyValue('--safe-area-inset-left')
  const right = getComputedStyle(element).getPropertyValue('--safe-area-inset-right')
  const bottom = getComputedStyle(element).getPropertyValue('--safe-area-inset-bottom')

  return {
    top,
    left,
    right,
    bottom,
  }
}
