// @file composable that calls a handler once a user has swiped down
import device from '@@/bits/device'

const TOUCH_THRESHOLD = 10 // pixels

// Calls handler once if the touch is in vertical direction
// After that, no need to listen for touch events anymore
export function useHandleFirstVerticalSwipe(handler: () => void): void {
  if (!device.touchable) return

  let initialTouchX = 0
  let initialTouchY = 0

  const handleTouchStart = (e: TouchEvent): void => {
    initialTouchX = e.touches[0].clientX
    initialTouchY = e.touches[0].clientY
  }

  const handleTouchEnd = (e: TouchEvent): void => {
    const finalTouchX = e.changedTouches[0].clientX
    const finalTouchY = e.changedTouches[0].clientY
    const deltaX = Math.abs(finalTouchX - initialTouchX)
    const deltaY = Math.abs(finalTouchY - initialTouchY)
    const isVerticalTouch = deltaY / deltaX > 0 && deltaY > TOUCH_THRESHOLD
    if (isVerticalTouch) {
      handler()
      document.body.removeEventListener('touchstart', handleTouchStart)
      document.body.removeEventListener('touchend', handleTouchEnd)
    }
  }

  document.body.addEventListener('touchstart', handleTouchStart)
  document.body.addEventListener('touchend', handleTouchEnd)
}
