// @file Native post action menu
import appCan, { isAppCapableOf } from '@@/bits/app_can'
import { getStaticAttachmentType, isAttachmentDownloadable, StaticAttachmentType } from '@@/bits/attachments'
import { getVuexStore } from '@@/bits/pinia'
import { isPostPinned, isPostScheduled } from '@@/bits/post_state'
import { getPollFromPost, isPollClosed } from '@@/bits/surface_polls'
import { listen } from '@@/native_bridge/listen'
import { showNativePostActionMenu } from '@@/native_bridge/post_action_menu'
import type { ActionMenuWallData, ListenedMessage, ListenedMessageHandler } from '@@/native_bridge/types'
import {
  createCopyPostSidePanel,
  createPostActionAlertSheet,
  createTransferPostSidePanel,
  PostActionMenuItem,
  showNativeUI,
} from '@@/native_bridge/ui'
import { useExpandedPostStore } from '@@/pinia/expanded_post'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceAttachmentsStore } from '@@/pinia/surface_attachments'
import { useSurfaceMapStore } from '@@/pinia/surface_map'
import { useSurfacePermissionsStore } from '@@/pinia/surface_permissions'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { useSurfacePostActionStore } from '@@/pinia/surface_post_action'
import { useSurfacePostConnectionStore } from '@@/pinia/surface_post_connection'
import { useSurfacePostPropertiesStore } from '@@/pinia/surface_post_properties'
import * as sorter from '@@/surface/sorter'
import type { Post } from '@@/types'
import { PostActionType } from '@@/vuexstore/helpers/post'
import type { RootState } from '@@/vuexstore/surface/types'
import type { PostColor } from '@padlet/arvo'
import { storeToRefs } from 'pinia'
import { computed, watch } from 'vue'

const THEME_COLORS: PostColor[] = ['red', 'orange', 'green', 'blue', 'purple']

export const useNativePostActionMenu = (): void => {
  const vuexStore = getVuexStore<RootState>()
  const surfaceStore = useSurfaceStore()
  const surfacePostActionStore = useSurfacePostActionStore()
  const expandedPostStore = useExpandedPostStore()
  const surfaceAttachmentsStore = useSurfaceAttachmentsStore()
  const surfacePostsStore = useSurfacePostsStore()
  const surfacePostPropertiesStore = useSurfacePostPropertiesStore()
  const surfacePostConnectionStore = useSurfacePostConnectionStore()
  const surfaceMapStore = useSurfaceMapStore()

  const { postUnderAction, postActionMenuTriggerRect } = storeToRefs(surfacePostActionStore)
  const { canIAccessContentDirectly } = storeToRefs(useSurfacePermissionsStore())

  const actionMenuWallData = computed<ActionMenuWallData>(() => ({
    publicKey: surfaceStore.publicKey,
    links: surfaceStore.links,
    themeColors: THEME_COLORS,
    colorScheme: surfaceStore.colorScheme,
    mobileAppCopyPostUrlTemplate: vuexStore?.state.constants.mobileAppCopyPostUrlTemplate,
    mobileAppTransferPostUrlTemplate: vuexStore?.state.constants.mobileAppTransferPostUrlTemplate,
  }))

  const showPostActionMenuV2 = async (post: Post): Promise<void> => {
    const isPostUnderActionExpanded = post.id === expandedPostStore.expandedPostId
    let isPostAttachmentDownloadable = false
    let postStaticAttachmentType: StaticAttachmentType = StaticAttachmentType.Unknown

    // We only care about where the attachment is downloadable or not for expanded post view
    if (isPostUnderActionExpanded && surfacePostActionStore.postUnderActionCid != null) {
      if (surfaceAttachmentsStore.getLinkDisplayAttributesForPost(surfacePostActionStore.postUnderActionCid) == null) {
        await surfaceAttachmentsStore.fetchDisplayAttributesForPost(surfacePostActionStore.postUnderActionCid)
      }
      const attachmentDisplayAttributes = surfaceAttachmentsStore.getLinkDisplayAttributesForPost(
        surfacePostActionStore.postUnderActionCid,
      )
      isPostAttachmentDownloadable = !!post.attachment && isAttachmentDownloadable(attachmentDisplayAttributes)
      postStaticAttachmentType = getStaticAttachmentType(attachmentDisplayAttributes)
    }

    const group0: PostActionType[] = []
    const group1: PostActionType[] = []
    const group2: PostActionType[] = []
    const group3: PostActionType[] = []
    const group4: PostActionType[] = []
    const group5: PostActionType[] = []

    // GROUP 0 - Poll specific, to always be replaced before the last group
    const poll = getPollFromPost(post)
    if (poll != null && surfacePostActionStore.canEditPostUnderAction) {
      group0.push(isPollClosed(poll) ? PostActionType.OpenPoll : PostActionType.ClosePoll)
      group0.push(PostActionType.ExportPollResultsCSV)
    }

    // Build the action list
    if (isPostUnderActionExpanded) {
      // should match the logic in SurfacePanelPostExpandedActionMenu.vue with some slight differences.
      // Detailed Figma: https://www.figma.com/file/SmrcMyYT7uv83MXTsypoho/Post-Expanded-View-v1---ENG?node-id=8494%3A114599

      // GROUP 1
      if (appCan('nativePostActionMenuWithSharing')) {
        group1.push(PostActionType.SharePostLink)
      } else {
        group1.push(PostActionType.CopyPostLink)
      }
      if (appCan('useNativeSlideshowWebview') && !isPostScheduled(post)) {
        group1.push(PostActionType.StartSlideshowFromThisPost)
      }
      if (isAppCapableOf('appOpenInMap') && surfacePostActionStore.canOpenInMapApp) {
        group1.push(PostActionType.OpenLocationInMapApp)
      }

      // GROUP 2
      if (post.attachment) {
        if (appCan('nativePostActionMenuWithSharing')) {
          if (isPostAttachmentDownloadable) {
            group2.push(PostActionType.ShareStaticAttachment)
          }
          group2.push(PostActionType.ShareAttachmentLink)
        } else {
          group2.push(PostActionType.CopyAttachmentLink)
        }
      }

      // GROUP 3
      if (surfacePostActionStore.canEditPostUnderAction) {
        group3.push(PostActionType.EditPost)
      }

      // GROUP 4
      if (!isPostScheduled(post)) {
        if (surfacePostActionStore.canCopyPostUnderAction) {
          group4.push(PostActionType.DuplicatePost)
        }
        if (surfacePostActionStore.canTransferPostUnderAction) {
          group4.push(PostActionType.TransferPost)
        }
      }
      if (
        surfacePostActionStore.canChangeCoverToPostUnderAction &&
        surfacePostActionStore.isPostUnderActionPreviewPost
      ) {
        group4.push(PostActionType.UnsetAsPadletCover)
      }
      if (
        surfacePostActionStore.canChangeCoverToPostUnderAction &&
        !surfacePostActionStore.isPostUnderActionPreviewPost
      ) {
        group4.push(PostActionType.SetAsPadletCover)
      }

      // GROUP 5
      if (appCan('surfaceAbuseReportPanel') && !isPostScheduled(post)) {
        group5.push(PostActionType.ReportPost)
      }
      if (surfacePostActionStore.canDeletePostUnderAction) {
        group5.push(PostActionType.DeletePost)
      }
    } else {
      // should match the logic in SurfacePanelPostActionMenu.vue with some slight differences.
      // Detailed Figma: https://www.figma.com/file/SmrcMyYT7uv83MXTsypoho/Post-Expanded-View-v1---ENG?node-id=8494%3A114599

      // GROUP 1
      group1.push(PostActionType.ExpandPost, PostActionType.CopyPostLink)

      if (appCan('useNativeSlideshowWebview') && !isPostScheduled(post)) {
        group1.push(PostActionType.StartSlideshowFromThisPost)
      }
      if (isAppCapableOf('appOpenInMap') && surfacePostActionStore.canOpenInMapApp) {
        group1.push(PostActionType.OpenLocationInMapApp)
      }

      // GROUP 2
      if (surfacePostActionStore.canEditPostUnderAction) {
        if (surfacePostPropertiesStore.isColorEnabled) {
          group2.push(PostActionType.ChangePostColor)
        }
        group2.push(PostActionType.EditPost)
      }
      if (surfacePostActionStore.canConnectPostUnderAction) {
        group2.push(PostActionType.ConnectToPost)
      }
      if (surfacePostActionStore.canDisconnectPostUnderAction) {
        group2.push(PostActionType.DisconnectFromPost)
      }
      if (surfacePostActionStore.canChangePostStackOrder) {
        group2.push(PostActionType.BringToFront)
        group2.push(PostActionType.SendToBack)
      }

      // GROUP 3
      if (!isPostScheduled(post)) {
        if (surfacePostActionStore.canCopyPostUnderAction) {
          group3.push(PostActionType.DuplicatePost)
        }
        if (surfacePostActionStore.canTransferPostUnderAction) {
          group3.push(PostActionType.TransferPost)
        }
      }
      if (
        surfacePostActionStore.canChangeCoverToPostUnderAction &&
        surfacePostActionStore.isPostUnderActionPreviewPost
      ) {
        group3.push(PostActionType.UnsetAsPadletCover)
      }
      if (
        surfacePostActionStore.canChangeCoverToPostUnderAction &&
        !surfacePostActionStore.isPostUnderActionPreviewPost
      ) {
        group3.push(PostActionType.SetAsPadletCover)
      }

      // GROUP 4
      if (surfacePostsStore.canPinPost(post)) {
        group4.push(isPostPinned(post) ? PostActionType.UnpinPost : PostActionType.PinPost)
      }
      if (appCan('surfaceAbuseReportPanel') && !isPostScheduled(post)) {
        group4.push(PostActionType.ReportPost)
      }
      if (surfacePostActionStore.canDeletePostUnderAction) {
        group4.push(PostActionType.DeletePost)
      }
    }

    const desiredActionGroups = (
      isPostUnderActionExpanded
        ? [group1, group2, group3, group4, group0, group5]
        : [group1, group2, group3, group0, group4]
    ).filter((group) => group.length > 0)

    showNativePostActionMenu({
      post,
      wall: actionMenuWallData.value,
      linkDisplayAttributes: surfaceAttachmentsStore.getLinkDisplayAttributesForPost(post.cid),
      desiredActionGroups,
      isPostUnderActionExpanded,
      isPostAttachmentDownloadable,
      areAttachmentsAccessible: canIAccessContentDirectly.value,
      postStaticAttachmentType,
      triggerRect: postActionMenuTriggerRect.value,
    })
  }

  const showPostActionMenuV1 = (post: Post): void => {
    // Specify which options will appear in the alert sheet.
    const sheetOptions: PostActionMenuItem[] = []
    if (surfacePostActionStore.canEditPostUnderAction) {
      sheetOptions.push(PostActionMenuItem.Colors)
      sheetOptions.push(PostActionMenuItem.Edit)
    }
    if (surfacePostActionStore.canChangePostUnderActionLocation) {
      sheetOptions.push(PostActionMenuItem.ChangeLocation)
    }
    if (surfacePostActionStore.canConnectPostUnderAction) {
      sheetOptions.push(PostActionMenuItem.Connect)
    }
    if (surfacePostActionStore.canDisconnectPostUnderAction) {
      sheetOptions.push(PostActionMenuItem.Disconnect)
    }
    if (surfacePostActionStore.canChangePostStackOrder) {
      sheetOptions.push(PostActionMenuItem.BringPostToFront)
      sheetOptions.push(PostActionMenuItem.SendPostToBack)
    }
    if (
      surfacePostActionStore.canEditPostUnderAction ||
      surfacePostActionStore.canConnectPostUnderAction ||
      surfacePostActionStore.canDisconnectPostUnderAction
    ) {
      sheetOptions.push(PostActionMenuItem.Divider)
    }
    if (surfacePostActionStore.canTransferPostUnderAction) {
      sheetOptions.push(PostActionMenuItem.Transfer)
    }
    if (surfacePostActionStore.canCopyPostUnderAction) {
      sheetOptions.push(PostActionMenuItem.Copy)
    }
    if (expandedPostStore.expandedPost == null) {
      sheetOptions.push(PostActionMenuItem.Expand)
    }
    if (surfacePostActionStore.canChangeCoverToPostUnderAction) {
      sheetOptions.push(PostActionMenuItem.Divider)
      if (surfacePostActionStore.isPostUnderActionPreviewPost) {
        sheetOptions.push(PostActionMenuItem.UnsetAsCover)
      } else {
        sheetOptions.push(PostActionMenuItem.SetAsCover)
      }
    }
    if (surfacePostActionStore.canDeletePostUnderAction) {
      sheetOptions.push(PostActionMenuItem.Divider)
      sheetOptions.push(PostActionMenuItem.Delete)
    }

    const alertSheet = createPostActionAlertSheet(
      { id: Number(post.id), cid: post.cid, author_id: Number(post.author_id) },
      actionMenuWallData.value,
      sheetOptions,
    )
    showNativeUI(alertSheet)
  }

  const showPostActionMenu = async (post: Post): Promise<void> => {
    if (appCan('nativePostActionMenuV2')) {
      await showPostActionMenuV2(post)
    } else {
      showPostActionMenuV1(post)
    }
  }

  const listenToPostUnderActionMenu = (postUnderAction: Post): void => {
    const postId = Number(postUnderAction.id)
    const postCid = postUnderAction.cid
    const authorId = Number(postUnderAction.author_id)
    const hideMenuFirst = (wrapped: Function): ListenedMessageHandler => {
      return (msg: unknown): void => {
        surfacePostActionStore.hidePostActionMenu()
        wrapped(msg)
      }
    }
    listen(
      'edit_post',
      hideMenuFirst((): void => {
        surfacePostsStore.startEditingPost({ postCid })
      }),
    )
    listen(
      'connect_post',
      hideMenuFirst((): void => {
        surfacePostConnectionStore.startConnection({ postId })
      }),
    )
    listen(
      'disconnect_post',
      hideMenuFirst((): void => {
        surfacePostConnectionStore.startDisconnection({ postId })
      }),
    )
    listen(
      'bring_post_to_front',
      hideMenuFirst((): void => {
        surfacePostsStore.sortPost({ postCid, sortIndex: sorter.getTopSortIndex() })
      }),
    )
    listen(
      'send_post_to_back',
      hideMenuFirst((): void => {
        surfacePostsStore.sortPost({ postCid, sortIndex: sorter.getBottomSortIndex() })
      }),
    )
    listen(
      'expand_post',
      hideMenuFirst((): void => {
        expandedPostStore.expandPost({ postCid })
      }),
    )
    listen(
      'delete_post',
      hideMenuFirst((): void => {
        void surfacePostsStore.deletePost(postCid)
      }),
    )
    listen(
      'update_post_color',
      hideMenuFirst((msg: ListenedMessage & { label: PostColor }): void => {
        surfacePostActionStore.changePostColor({ postCid, newBgColor: msg.label })
      }),
    )
    listen(
      'remove_post_color',
      hideMenuFirst((): void => {
        surfacePostActionStore.removePostColor({ postCid })
      }),
    )
    listen(
      'set_as_cover',
      hideMenuFirst((): void => {
        surfacePostActionStore.setCoverPost({ postId })
      }),
    )
    listen(
      'unset_as_cover',
      hideMenuFirst((): void => {
        surfacePostActionStore.unsetCoverPost({ postId })
      }),
    )
    listen(
      'change_location',
      hideMenuFirst((): void => {
        surfaceMapStore.startPickingLocation({ postCid })
      }),
    )
    listen(
      'transfer_post',
      hideMenuFirst((): void => {
        // track TODO
        showNativeUI(
          createTransferPostSidePanel(
            vuexStore?.state.constants.mobileAppTransferPostUrlTemplate,
            surfaceStore.publicKey,
            {
              id: postId,
              author_id: authorId,
            },
          ),
        )
        surfacePostActionStore.startPostTransfer({ transferPostCid: postCid })
      }),
    )
    listen('copy_post', (): void => {
      // track TODO
      showNativeUI(
        createCopyPostSidePanel(vuexStore?.state.constants.mobileAppCopyPostUrlTemplate, surfaceStore.publicKey, {
          id: postId,
          author_id: authorId,
        }),
      )
      surfacePostActionStore.startPostCopy({ copyPostCid: postCid })
    })

    listen(
      'close_poll',
      hideMenuFirst((): void => {
        void surfacePostActionStore.closePoll({ post: postUnderAction })
      }),
    )

    listen(
      'open_poll',
      hideMenuFirst((): void => {
        void surfacePostActionStore.reopenPoll({ post: postUnderAction })
      }),
    )
  }

  watch(postUnderAction, (newPost) => {
    if (newPost == null) return

    listenToPostUnderActionMenu(newPost)
    void showPostActionMenu(newPost)
  })
}
