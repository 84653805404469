// @file Native bridge manifest builders for mobile app widgets
/* eslint-disable camelcase, @typescript-eslint/camelcase */
import { __ } from '@@/bits/intl'
import { postColorRgb } from '@@/bits/post_color'
import postMessage from '@@/native_bridge/post_message'
import type {
  Alert,
  AlertSheet,
  Button,
  ColorMenuItem,
  DomElement,
  Launch,
  MenuItem,
  SidePanel,
  UIAction,
  UIItem,
} from '@@/native_bridge/types'
import type { CommentId } from '@@/types'
import type { PostColor } from '@padlet/arvo'

// ======================================================
//                    UTILITIES
// ======================================================
interface PostColorOption {
  label: PostColor
  red: number
  green: number
  blue: number
  alpha: number
  color_as_rgb: string
}

/**
 * We use CSS variables to communicate between our stylesheets and Javascript
 * so we can present the right background colors to the mobile app when it launches
 * the post color selector.
 *
 * Note that this only possibly changes between page loads, so we cache it in a
 * module level variable and return that if it's already populated.
 */
const getPossiblePostColors = (allPostColorLabels: PostColor[], colorScheme: 'light' | 'dark'): PostColorOption[] => {
  const isLightColorScheme = colorScheme === 'light'
  return allPostColorLabels
    .map((label): PostColorOption | null => {
      const colorVar = postColorRgb({ postColor: label, isLightColorScheme })
      if (!colorVar) return null
      return {
        label,
        red: colorVar.r,
        green: colorVar.g,
        blue: colorVar.b,
        alpha: 1.0,
        color_as_rgb: `rgb(${colorVar.r}, ${colorVar.g}, ${colorVar.b})`,
      }
    })
    .filter((co): boolean => !!co) as PostColorOption[]
}

/**
 * All UIItems have a type. All this function does is post
 * message a "show" message with the type and its manifest.
 * @param manifest An object specifying what to display.
 */
const showNativeUI = (manifest: UIItem): void => {
  postMessage({
    message_type: `show_${manifest.type}`,
    manifest,
  })
}

// ======================================================
//                    MENU ITEMS
// ======================================================
interface MenuItemOptions {
  name: string
  icon: string
  launches?: Launch
  action?: UIAction
  key?: string
  style?: 'default' | 'destructive'
  disabled?: boolean
  actionHandledNatively?: boolean
}
const createNativeMenuItem = (options: MenuItemOptions): MenuItem => {
  const menuItem: MenuItem = {
    type: 'menu_item',
    name: options.name,
    icon: options.icon,
    key: options.key,
    style: options.style,
    disabled: options.disabled ?? false,
    actionHandledNatively: options.actionHandledNatively ?? false,
  }

  if (options.launches) {
    const { launches } = options
    menuItem.action = {
      message_type: launches.actionMessageType!,
      launches: launches.type,
      launch_manifest: launches!,
    }
    launches.actionMessageType = undefined
  } else if (options.action) {
    menuItem.action = options.action
  } else {
    throw new Error('Must launch or have an action')
  }
  return menuItem
}

// ======================================================
//                    DIVIDER
// ======================================================
const createNativeDivider = (): MenuItem => ({ type: 'menu_divider' })

// ======================================================
//                    ALERT SHEET
// ======================================================
interface AlertSheetOptions {
  title: string
  element: DomElement
  menuItems: MenuItem[]
  cancelAction?: UIAction
}
const createNativeAlertSheet = (options: AlertSheetOptions): AlertSheet => {
  const { title, element, menuItems, cancelAction } = options
  if (!cancelAction) {
    throw new Error(`Cancel action required for alert sheet`)
  }
  if (!menuItems) {
    throw new Error(`Menu items required for alert sheet`)
  }
  return {
    type: 'alert_sheet',
    title,
    element,
    items: menuItems,
    cancel_action: cancelAction,
  }
}

// ======================================================
//                    BUTTON
// ======================================================
interface ButtonOptions {
  name: string
  style?: unknown
  action: UIAction
}
const createNativeButton = (options: ButtonOptions): Button => {
  const { name, action, style } = options
  return {
    type: 'button',
    name,
    style,
    action,
    title: name,
  }
}

// ======================================================
//                    ALERT
// ======================================================
interface AlertOptions {
  title: string
  body: string
  buttons: Button[]
  cancelAction: UIAction
}
const createNativeAlert = (options: AlertOptions): Alert => {
  const { title, body, buttons, cancelAction } = options
  return {
    type: 'alert',
    title,
    body,
    buttons,
    cancel_action: cancelAction,
  }
}

// ======================================================
//                    DOM
// ======================================================
/**
 * Gets the top offset and left offset for an element relative to the document.
 * This is the equivalent of jQuery's $.offset() method.
 * Taken from: https://plainjs.com/javascript/styles/get-the-position-of-an-element-relative-to-the-document-24/
 */
function getElementOffset(element: Element): { top: number; left: number } {
  const rect = element.getBoundingClientRect()
  const documentElement = document.documentElement
  const scrollLeft = window.pageXOffset || documentElement ? documentElement.scrollLeft : 0
  const scrollTop = window.pageYOffset || documentElement ? documentElement.scrollTop : 0
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}
const createDomElement = (element: Element): DomElement => {
  const offset = getElementOffset(element)
  const boundingRect = element.getBoundingClientRect()
  return {
    type: 'element',
    x: offset.left,
    y: offset.top,
    width: boundingRect.width,
    height: boundingRect.height,
  }
}

// ======================================================
//                    SIDEPANEL
// ======================================================
interface SidePanelOptions {
  url: string
  title?: string
  metadata: unknown | null
  leftButton?: Button | null
  rightButton?: Button | null

  // snake-case equivalents
  left_button?: Button | null
  right_button?: Button | null
}
const createNativeSidePanel = (options: SidePanelOptions): SidePanel => {
  const manifest = {
    type: 'side_panel',
    title: options.title,
    url: options.url,
    metadata: options.metadata || null,
    cancel_action: {
      message_type: 'hide_side_panel',
    },
    left_button: options.leftButton || options.left_button || null,
    right_button: options.rightButton || options.right_button || null,
  }
  return manifest
}

// ======================================================
//                    COMPOSED UI
// ======================================================
const createTransferPostSidePanel = (
  templateUrl: string,
  wallPublicKey: string,
  post: { id?: number; author_id?: number },
): SidePanel => {
  const url = templateUrl
    .replace('_wallPublicKey_', wallPublicKey)
    .replace('_wishId_', (post.id as number).toString())
    .replace('_host_', window.location.hostname)

  const leftButton = createNativeButton({
    name: __('Cancel'),
    style: 'cancel',
    action: {
      message_type: 'hide_side_panel',
    },
  })
  const metadata = { post_id: post.id, action: 'transfer_post', author_id: post.author_id }
  return createNativeSidePanel({
    title: __('Transfer post'),
    leftButton,
    metadata,
    url,
  })
}

const createCopyPostSidePanel = (
  templateUrl: string,
  wallPublicKey: string,
  post: { id?: number; author_id?: number },
): SidePanel => {
  const url = templateUrl
    .replace('_wallPublicKey_', wallPublicKey)
    .replace('_wishId_', (post.id as number).toString())
    .replace('_host_', window.location.hostname)

  const leftButton = createNativeButton({
    name: __('Cancel'),
    style: 'cancel',
    action: {
      message_type: 'hide_side_panel',
    },
  })
  const metadata = {
    post_id: post.id,
    action: 'copy_post',
    author_id: post.author_id,
  }
  return createNativeSidePanel({
    title: __('Copy post'),
    leftButton,
    metadata,
    rightButton: null,
    url,
  })
}

const createAskToDeletePostAlert = (postId: number): Alert => {
  return createNativeAlert({
    title: __('Delete post'),
    body: __('Are you sure you want to delete this post? This cannot be undone!'),
    buttons: [
      // LEFT BUTTON
      createNativeButton({
        name: __('Cancel'),
        action: {
          message_type: 'cancel_show',
        },
        style: 'cancel',
      }),
      // RIGHT BUTTON
      createNativeButton({
        name: __('Delete'),
        action: {
          message_type: 'delete_post',
          post_id: postId,
        },
        style: 'destructive',
      }),
    ],
    cancelAction: {
      message_type: 'cancel_show',
    },
  })
}

const createAskToDeleteCommentAlert = (commentId: CommentId): Alert => {
  return createNativeAlert({
    title: __('Delete comment'),
    body: __('Are you sure you want to delete this comment? This cannot be undone!'),
    buttons: [
      // LEFT BUTTON
      createNativeButton({
        name: __('Cancel'),
        action: {
          message_type: 'cancel_show',
        },
        style: 'cancel',
      }),
      // RIGHT BUTTON
      createNativeButton({
        name: __('Delete'),
        action: {
          message_type: 'delete_comment',
          comment_id: commentId,
        },
        style: 'destructive',
      }),
    ],
    cancelAction: {
      message_type: 'cancel_show',
    },
  })
}

const createCommentMenuAlertSheet = (commentId: CommentId): AlertSheet => {
  const element = document.getElementById(`comment-${commentId}`)!
  const menuItems: MenuItem[] = []
  menuItems.push(
    createNativeMenuItem({
      name: __('Edit comment'),
      icon: 'edit_comment',
      action: {
        message_type: 'edit_comment',
        comment_id: commentId,
      },
    }),
  )
  menuItems.push(
    createNativeMenuItem({
      name: __('Delete comment'),
      icon: 'ask_to_delete',
      launches: {
        ...createAskToDeleteCommentAlert(commentId),
        actionMessageType: 'ask_to_delete',
      },
    }),
  )
  return createNativeAlertSheet({
    cancelAction: {
      message_type: 'cancel_show',
    },
    element: createDomElement(element),
    menuItems,
    title: '',
  })
}

const createAskToDeleteSectionAlert = (sectionId: number): Alert => {
  const cancelButton = createNativeButton({
    name: __('Cancel'),
    action: {
      message_type: 'cancel_show',
    },
    style: 'cancel',
  })

  const deleteButton = createNativeButton({
    name: __('Delete'),
    action: {
      message_type: 'delete_section',
      section_id: sectionId,
    },
    style: 'destructive',
  })

  return createNativeAlert({
    title: __('Delete section'),
    body: __('Are you sure you want to delete this section and all its posts? This cannot be undone!'),
    buttons: [cancelButton, deleteButton],
    cancelAction: { message_type: 'cancel_show' },
  })
}

const createNativeColorMenuItem = (postColorLabels: PostColor[], colorScheme: 'light' | 'dark'): ColorMenuItem => {
  let colorOptions = [
    {
      label: 'no-color',
      action: {
        message_type: 'remove_post_color',
      },
    },
  ]
  colorOptions = colorOptions.concat(
    getPossiblePostColors(postColorLabels, colorScheme).map((color): any => {
      return {
        ...color,
        action: {
          message_type: 'update_post_color',
          label: color.label,
        },
      }
    }),
  )
  return {
    type: 'color_menu_item',
    colors: colorOptions,
  }
}

enum PostActionMenuItem {
  Colors,
  Edit,
  Connect,
  Disconnect,
  BringPostToFront,
  SendPostToBack,
  Transfer,
  Copy,
  Expand,
  Delete,
  SetAsCover,
  UnsetAsCover,
  Divider,
  ChangeLocation,
}

interface Post {
  id: number
  cid: string
  author_id: number
}
interface Wall {
  publicKey: string
  themeColors: PostColor[]
  colorScheme: 'light' | 'dark'
  mobileAppCopyPostUrlTemplate: string
  mobileAppTransferPostUrlTemplate: string
}
const createPostActionAlertSheet = (post: Post, wall: Wall, desiredItems: PostActionMenuItem[]): AlertSheet => {
  const { id: postId, cid: postCid } = post
  const postElement = document.querySelector(`.surface-post[data-post-cid=${postCid}]`)

  const divider = createNativeDivider()
  const menuItems: MenuItem[] = []

  const menuItemsFactory: { [key in PostActionMenuItem]?: () => MenuItem } = {
    [PostActionMenuItem.Divider]: (): MenuItem => divider,
    [PostActionMenuItem.Edit]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Edit'),
        icon: 'edit_post',
        action: {
          message_type: 'edit_post',
          post_id: postId,
        },
      })
    },
    [PostActionMenuItem.Colors]: (): MenuItem => {
      return createNativeColorMenuItem(wall.themeColors, wall.colorScheme)
    },
    [PostActionMenuItem.Connect]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Connect to a post'),
        icon: 'connect_post',
        action: {
          message_type: 'connect_post',
          post_id: postId,
        },
      })
    },
    [PostActionMenuItem.Disconnect]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Disconnect from a post'),
        icon: 'disconnect_post',
        action: {
          message_type: 'disconnect_post',
          post_id: postId,
        },
      })
    },
    [PostActionMenuItem.BringPostToFront]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Bring to front'),
        icon: 'bring_post_to_front',
        action: {
          message_type: 'bring_post_to_front',
          post_id: postId,
        },
      })
    },
    [PostActionMenuItem.SendPostToBack]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Send to back'),
        icon: 'send_post_to_back',
        action: {
          message_type: 'send_post_to_back',
          post_id: postId,
        },
      })
    },
    [PostActionMenuItem.Transfer]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Transfer'),
        icon: 'transfer_post',
        launches: {
          ...createTransferPostSidePanel(wall.mobileAppTransferPostUrlTemplate, wall.publicKey, post),
          actionMessageType: 'transfer_post',
        },
      })
    },
    [PostActionMenuItem.Copy]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Copy'),
        icon: 'copy_post',
        launches: {
          ...createCopyPostSidePanel(wall.mobileAppCopyPostUrlTemplate, wall.publicKey, post),
          actionMessageType: 'copy_post',
        },
      })
    },
    [PostActionMenuItem.Expand]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Expand'),
        icon: 'expand_post',
        action: {
          message_type: 'expand_post',
          post_id: postId,
        },
      })
    },
    [PostActionMenuItem.UnsetAsCover]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Unset as cover'),
        icon: 'cover',
        action: {
          message_type: 'unset_as_cover',
          post_id: postId,
        },
      })
    },
    [PostActionMenuItem.SetAsCover]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Set as cover'),
        icon: 'cover',
        action: {
          message_type: 'set_as_cover',
          post_id: postId,
        },
      })
    },
    [PostActionMenuItem.Delete]: (): MenuItem => {
      const manifest = createAskToDeletePostAlert(postId)
      return createNativeMenuItem({
        name: __('Delete'),
        icon: 'ask_to_delete',
        launches: {
          ...manifest,
          actionMessageType: 'ask_to_delete',
        },
      })
    },
    [PostActionMenuItem.ChangeLocation]: (): MenuItem => {
      return createNativeMenuItem({
        name: __('Change location'),
        icon: 'change_location',
        action: {
          message_type: 'change_location',
          post_id: postId,
        },
      })
    },
  }

  desiredItems.forEach((desire): void => {
    const itemMaker = menuItemsFactory[desire]
    if (!itemMaker) return
    const menuItem = itemMaker()
    if (menuItem) {
      menuItems.push(menuItem)
    }
  })

  return createNativeAlertSheet({
    cancelAction: {
      message_type: 'cancel_show',
    },
    element: createDomElement(postElement!),
    title: __('Post menu'),
    menuItems,
  })
}

export {
  PostActionMenuItem,
  createAskToDeleteCommentAlert,
  createAskToDeletePostAlert,
  createCommentMenuAlertSheet,
  createCopyPostSidePanel,
  createDomElement,
  createNativeAlert,
  createNativeAlertSheet,
  createNativeButton,
  createNativeColorMenuItem,
  createNativeDivider,
  createNativeMenuItem,
  createNativeSidePanel,
  createPostActionAlertSheet,
  createTransferPostSidePanel,
  showNativeUI,
}
